.hero {
  color: #fff;
  padding: 100px;
  position: relative;
}
@media screen and (max-width: 991px) {
  .hero {
    margin-top: 20px;
    padding: 100px 0px;
  }
}
.hero:before {
  content: "";
  height: 90%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-color: #245d51;
}

.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  /* padding: 1rem; */
}

.hero_content{
  font-size: 1rem;
}

@media screen and (max-width: 940px) {
  .hero .content p:nth-child(3) {
    font-size: 3rem;
  }

  .hero .content p:nth-child(4) {
    font-size: 1.6rem;
  }
}
