.header {
  position: fixed;
  height: 100px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow:hidden;
  padding: 12px;
  background-color: #245d51;
}


.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  height: 100%;
}

.header .nav-menu a {
  color: #ffffff;
}

.header .navbar img {
  width: 250px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding-left: 40px;
  padding-top: 20px;
  font-weight: 500;
}
.nav-item a {
  font-size: 1rem;
}
.header .nav-item a:hover {
  /* padding-bottom: 12px; */
  border-bottom: 3px solid #ffaa3b;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    background-color: #245d51;
    background-size: cover;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: #245d51;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1rem 0;
    margin-left: -10%;
  }
  a {
    text-decoration: none;
    color: white;
    text-align: center;
   
  }
  .nav-item a{
    left: 10%;
  }
  .header .navbar img {
    width: 150px;
  }
}
