#contact{
  scroll-margin-top: 100px;

}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* input{
  height: 50px;
  width: 90%;

}
.message{
  height:40px;
} */