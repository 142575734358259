.wanga_amigos .header {
  position: fixed;

  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;

  background-color: #FFFFFF;
}

.wanga_amigos .header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
}

.wanga_amigos .header .nav-menu a {
  color: #1f1f1f;
}

.wanga_amigos .header .nav-menu {
  display: flex;
}

.wanga_amigos .header .nav-item {
  padding-left: 40px;
  padding-top: 20px;
  font-weight: 500;
}
.wanga_amigos .nav-item a {
  font-size: 1rem;
}
.wanga_amigos .header .nav-item a:hover {
  /* padding-bottom: 12px; */
  border-bottom: 3px solid #ffaa3b;
}

.wanga_amigos .hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .wanga_amigos .header {
    max-width: 100%;
    background-color: #faf4f4;
    background-size: cover;
  }

  .wanga_amigos .wanga_amigos .header .navbar {
    max-width: 100%;
  }

  .wanga_amigos .hamburger {
    display: block;
  }

  .wanga_amigos .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: #245d51;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .wanga_amigos .nav-menu.active {
    left: 0;
  }

  .wanga_amigos .nav-item {
    margin: 1rem 0;
    margin-left: -5% !important;
  }
  .wanga_amigos a {
    text-decoration: none;
    color: #ffffff !important;
    text-align: center;
  }
  .wanga_amigos .nav-item a {
    left: 10%;
  }
  .wanga_amigos .header .navbar img {
    width: 150px;
  }
}
@media (min-width: 1200px) {
    .wanga_amigos .container{
        max-width: 1430px;
    }
  }
  