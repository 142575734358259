.testimonials {
    width: 100%;
    background-color: #F6FFFD;
}
.Testimonial_text{
    font-size: 3rem;

}
.testimonials .container {
    width: 1240px;
    margin: 4rem auto;
    padding: 4rem 0rem;
}



.testimonials .container .content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    margin: 1rem 0;
}

.testimonials .card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
    height: fit-content;
}

.testimonials img {
    border-radius: 50px;
    margin-top: -2rem;
}




@media screen and (max-width: 940px) {
    .testimonials .container {
        width: 100%;
    }

    .testimonials .container .content {
        grid-template-columns: 1fr;
    }

    .testimonials .card {
        max-width: 100%;
        margin: 1rem;
        height: auto;
    }
}









